import type { NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import Layout from "../components/Layout";
import styles from "../styles/Home.module.css";

import {
  useStoryblokState,
  getStoryblokApi,
  StoryblokComponent,
  ISbStoriesParams,
} from "@storyblok/react";

/*
const Home: NextPage = ({ story }:{ story?:any }) => {
  story = useStoryblokState(story, {});
  
  return (
    <div className={styles.container}>
      <Head>
        <title>Create Next App</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <header>
        <h1 className="text-3xl font-bold underline">
          { story ? story.name : 'My Site' }
        </h1>
      </header>

      

      <main className={styles.main}>
        <div className="container mx-auto">
          <StoryblokComponent blok={story.content} />
        </div>
      </main>

      <footer className={styles.footer}>
        <a
          href="/__repl"
          target="_blank"
          rel="noopener noreferrer"
        >
          Built on
          <span className={styles.logo}>
            <Image src="/replit.svg" alt="Replit Logo" width={20} height={18} />
          </span>
          Replit
        </a>
      </footer>
    </div>
  )
}

export default Home */

interface sbContext {
  preview: string;
}

export async function getStaticProps(context: sbContext) {
  // home is the default slug for the homepage in Storyblok
  let slug = "home";

  // load the draft version
  let sbParams: ISbStoriesParams = {
    version: "published", // or 'published'
  };

  //console.log(context);

  if (!context.preview) {
    sbParams.version = "draft";
  }

  const storyblokApi = getStoryblokApi();
  let { data } = await storyblokApi.get(`cdn/stories/${slug}`, sbParams);

  return {
    props: {
      story: data ? data.story : false,
      key: data ? data.story.id : false,
      preview: context.preview || false,
    },
    revalidate: 3600, // revalidate every hour
  };
}

const IndexPage: NextPage = ({ story }: { story?: any }) => {
  story = useStoryblokState(story, {});
  return (
    <Layout title={story ? story.name : "My Site"}>
      <StoryblokComponent blok={story?.content} />
    </Layout>
  );
};

export default IndexPage;
